

<template>
	<div class="tcontainer" ref="container">
		<Toast />
		<div style="padding: 10px 3px">
			<div style="float: right">
			</div>
			<router-link to="/neurologist/reports/pending">
				<button style="margin: 0 5px;" :class="{ 'btn-secondary': page != 'pending', 'btn-primary': page == 'pending' }"
					class="btn btn-lg">Pending ({{ neurologist?.total_accepted_reports }}/10)</button>
			</router-link>
			<router-link to="/neurologist/reports/completed">
				<button style="margin: 0 5px;"
					:class="{ 'btn-secondary': page != 'completed', 'btn-primary': page == 'completed' }"
					class="btn btn-lg btn-primary">Completed</button>
			</router-link>
			<router-link to="/neurologist/reports/review">
				<button style="margin: 0 5px;" :class="{ 'btn-secondary': page != 'review', 'btn-primary': page == 'review' }"
					class="btn btn-lg btn-primary">Review ({{ neurologist?.total_review_reports }})</button>
			</router-link>
		</div>

		<div class="clearfix ttitle">
			<button @click="loadLazyData" class="pull-right btn btn-info" title="Reload listing"><i
					class="fa fa-refresh"></i></button>
			<div class="pull-right" style="margin-top: 10px; margin-right: 15px">Last Update: {{ format(refreshTime,
					"hh:mma")
			}}</div>
			<div class="title">Manage EEG Reports</div>
		</div>
		<DataTable stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true" columnResizeMode="fit"
			:paginator="true" :rows="100" v-model:filters="filters" ref="dt" :totalRecords="totalRecords"
			:rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
			@filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll">


			<Column field="report_id" header="Id" filterField="report_id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Id" />
					</div>
				</template>
				<template #body="{ data }">

					<div class="tdata" :style="{ 'font-weight': data.accepted ? 'bold' : 'normal' }">
						<span v-if="data.urgent == 'y'"
							style="border: 1px solid gray; border-radius: 6px; padding: 4px;background: #f00; font-weight: bold; color: #fff;">
							{{ data['report_id'] }}
						</span>
						<span v-else
							style="border: 1px solid gray; border-radius: 6px; padding: 4px; font-weight: bold; ">{{ data['report_id'] }}</span>
						<span v-if="data.urgent == 'y'" class="text-danger ms-1" style="font-weight: bold">Urgent</span>
					</div>
				</template>
			</Column>

			<Column field="center_code" header="Center Code" filterField="center_code" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Center Code" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['center_code'] }}
					</div>
				</template>
			</Column>

			<Column field="patient_name" header="Patient Name" filterField="patient_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Patient Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" :style="{ 'font-weight': data.accepted ? 'bold' : 'normal' }">
						{{ data['patient_name'] }} ({{ data['patient_age'] }})
					</div>
				</template>
			</Column>

			<Column field="patient_gender" header="Gender" filterField="patient_gender" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<select v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control">
							<option value="">Select Gender</option>

							<option value="male">Male</option>
							<option value="female">Female</option>
						</select>
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" :style="{ 'font-weight': data.accepted ? 'bold' : 'normal' }">
						<div style="text-align: center;">
							<span v-if="data['patient_gender'] == 'male'" style="color: blue; font-size: 25px"><i
									class="fa fa-male"></i></span>
							<span v-if="data['patient_gender'] == 'female'" style="color: #ff859a;  font-size: 25px"><i
									class="fa fa-female"></i></span>
						</div>
					</div>
				</template>
			</Column>

			<Column field="symptoms" header="Symptoms" filterField="symptoms" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Symptoms" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['symptoms'] }}
					</div>
				</template>
			</Column>

			<Column field="created_at" header="Created At" filterField="created_at" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Created At" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" :style="{ 'font-weight': data.accepted ? 'bold' : 'normal' }">
						<div style="width: 65px; margin: auto;">
							{{ format(data['created_at'], "DD/MM/YY h:mmA") }}
						</div>
					</div>
				</template>
			</Column>

			<Column field="status" header="Status" filterField="status" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<select v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control">
							<option value="">Select Status</option>

							<option value="pending">Pending</option>
							<option value="reported">Reported</option>
							<option value="printed">Printed</option>
						</select>
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" :style="{ 'font-weight': data.accepted ? 'bold' : 'normal' }">
						<sup v-if="data.accepted && data['report'] && data['status'] == 'pending'"
							class="text-success pull-right">draft</sup>
						<span v-if="data['status'] == 'pending'" class="form-control p-0 text-center"
							style="background: #fd6d7b; color: #fff; padding: 5px; border-radius: 5px;">{{ data['status'] }}</span>
						<span v-else-if="data['status'] == 'review'" class="form-control p-0 text-center"
							style="background: #fd6d7b; color: #fff; padding: 5px; border-radius: 5px;">{{ data['status'] }}</span>
						<span v-else-if="data['status'] == 'reported'" class="form-control p-0 text-center bg-warning"
							style=" color: #fff; padding: 5px; border-radius: 5px;">{{ data['status'] }}</span>
						<span v-else class="form-control p-0 text-center bg-success"
							style="color: #fff; padding: 5px; border-radius: 5px;">{{ data['status'] }}</span>
					</div>
				</template>
			</Column>

			<Column
				header="Tools &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;">
				<template #body="{ data }">
					<div class="tdata options">
						<button @click="showClinicalData(data)" class="btn btn-sm btn-outline-warning">
							<i class="fa fa-bars"></i>
						</button>
						<button @click="showNotesBox(data)" class="btn btn-sm btn-outline-info"
							:style="{ color: data.notes ? 'green' : '' }">
							<i class="fa fa-sticky-note"></i>
						</button>
						<button v-if="data.review_notes" @click="showReviewNotesBox(data)"
							class="btn btn-sm btn-outline-secondary">
							<i class="fa fa-eye"></i>
						</button>

						<button v-if="data.images?.length" @click="showImageViewer(data)"
							class="btn btn-sm btn-outline-primary"><i class="fa fa-image"></i></button>
						<router-link v-if="data.assign_doc_id != '0' && (data.status == 'pending' || data.status == 'review')"
							:to="'/neurologist/report/' + data.report_id">
							<button class="btn btn-sm btn-outline-success"><i class="fa fa-edit"></i></button>
						</router-link>
						<button v-if="data.status == 'reported'" title="Print" type="button" class="btn btn-sm btn-outline-success" @click="printReport(data)">
							<i class="fa fa-print"></i>
						</button>
						<!-- <router-link v-if="data.status == 'reported' || data.status == 'printed'"
							:to="'/neurologist/print-eeg-report/' + data.report_id">
							<button type="button" class="btn btn-sm btn-outline-success ms-1">
								<i class="fa fa-print"></i>
							</button>
						</router-link> -->
					</div>
				</template>
			</Column>
			<Column header="Action">
				<template #body="{ data }">
					<div class="tdata options">

						<div v-if="data.status == 'pending' && data.assign_type != 'specific'">
							<InputSwitch @change="acceptReport(data)" v-model="data['accepted']" />
							<div :style="{ color: data.timer < '00:05:00' ? 'red' : 'inherit' }"><b>{{ data.timer }}</b></div>
						</div>

					</div>
				</template>
			</Column>
		</DataTable>

		<div class="modal fade" ref="notesModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-info text-white">
						<h5 class="modal-title">Notes</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div>
						<textarea v-model="notes" rows="6" class="form-control"></textarea>
					</div>
					<div class="modal-footer">
						<button type="button" @click="saveNotes" class="btn btn-primary">Save</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" ref="reviewNotesModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-info text-white">
						<h5 class="modal-title">Review Notes</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div style="padding: 20px;">
						{{ editRow?.review_notes }}
					</div>
				</div>
			</div>
		</div>


		<div class="modal fade" ref="clinicalDataModal" tabindex="-1">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header bg-info text-white">
						<h5 class="modal-title">Clinical Data</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div style="padding: 20px;">
						<div class="container">
							<div class="row">
								<div class="col-sm-6"><b>Test Name:</b> {{ editRow?.test }}</div>
								<div class="col-sm-6"><b>Activation Procedure:</b> {{ editRow?.activation_procedure }}</div>
								<div class="col-sm-6"><b>Sedation Given:</b> {{ editRow?.sedation_given == 'y' ? 'Yes' : 'No' }}</div>
								<div class="col-sm-6"><b>Medicine:</b> {{ editRow?.medicine }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" ref="imageViewerModal" tabindex="-1">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header bg-info text-white">
						<h5 class="modal-title">Images</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div>
						<div style="width: 200px; float: left;">
							<div style="margin-bottom: 10px;" v-for="image in allImages" :key="image">
								<img v-if="image.isPdf" style="max-width: 100%; cursor: pointer;" @click="showImage = image"
									src="../../assets/pdf.png" alt="">
								<img v-else @click="showImage = image" style="max-width: 100%; cursor: pointer;"
									:src="apiOrigin + '/images/neurology/' + image.file" alt="">
							</div>
						</div>
						<div style="margin-left: 210px;">
							<div>
								<div style="padding: 10px;" class="pull-right" v-if="editRow.status == 'pending' && editRow.assign_type != 'specific'">
									<InputSwitch @change="acceptReport(editRow); imageViewerModal.hide();" v-model="editRow['accepted']" />
									<div :style="{ color: editRow.timer < '00:05:00' ? 'red' : 'inherit' }"><b>{{ editRow.timer }}</b>
									</div>
								</div>
								<div style="padding: 10px;" class="pull-right">
									<button v-if="editRow.status == 'pending' && editRow.assign_doc_id == '0' && editRow.assign_type != 'specific' && !editRow['accepted']" @click="deletePivotReport(editRow)"
									class="btn btn-sm btn-outline-danger"><i class="fa fa-trash"></i></button>
								</div>
							</div>
							<embed v-if="showImage?.isPdf" :src="apiOrigin + '/images/neurology/' + showImage.file"
								style="width: 100%; height: 675px;" type="application/pdf">
							<img v-if="showImage && !showImage?.isPdf" style="display: block; margin: auto; max-width: 100%"
								:src="apiOrigin + '/images/neurology/' + showImage.file" alt="">
						</div>

					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { Modal } from 'bootstrap'
import Toast from 'primevue/toast';
import InputSwitch from 'primevue/inputswitch';

export default {
	components: {
		Toast,
		InputSwitch
	},
	data() {
		return {
			loading: false,
			totalRecords: 0,
			tableRecords: null,
			editId: 0,
			editRow: {},
			saving: false,
			notesModal: null,
			reviewNotesModal: null,
			imageViewerModal: null,
			lazyParams: {},
			allImages: [],
			showImage: "",
			neurologist: null,
			notes: '',
			refreshTime: new Date(),
			timerWorking: false,
			page: 'pending',
			filters: {
				'report_id': { value: '', matchMode: 'like' },
				'center_id': { value: '', matchMode: 'like' },
				'center_code': { value: '', matchMode: 'like' },
				'patient_name': { value: '', matchMode: 'like' },
				'patient_age': { value: '', matchMode: 'like' },
				'patient_gender': { value: '', matchMode: '=' },
				'ref_doctor_name': { value: '', matchMode: 'like' },
				'bp_systole': { value: '', matchMode: 'like' },
				'bp_diastole': { value: '', matchMode: 'like' },
				'spo2': { value: '', matchMode: 'like' },
				'height': { value: '', matchMode: 'like' },
				'weight': { value: '', matchMode: 'like' },
				'symptoms': { value: '', matchMode: 'like' },
				'test_id': { value: '', matchMode: 'like' },
				'test_name': { value: '', matchMode: 'like' },
				'test_date': { value: '', matchMode: 'like' },
				'history': { value: '', matchMode: 'like' },
				'reg_no': { value: '', matchMode: 'like' },
				'mobile_no': { value: '', matchMode: 'like' },
				'assign_doc_id': { value: '', matchMode: 'like' },
				'assign_doc_ids': { value: '', matchMode: 'like' },
				'images': { value: '', matchMode: 'like' },
				'report': { value: '', matchMode: 'like' },
				'status': { value: '', matchMode: 'like' },
				'urgent': { value: '', matchMode: 'like' },
				'review_notes': { value: '', matchMode: 'like' },
				'created_at': { value: '', matchMode: 'like' },
				'reported_at': { value: '', matchMode: 'like' },
			},
		}
	},
	async mounted() {
		this.notesModal = new Modal(this.$refs.notesModal, { backdrop: 'static', keyboard: false });
		this.reviewNotesModal = new Modal(this.$refs.reviewNotesModal, { backdrop: 'static', keyboard: false });
		this.clinicalDataModal = new Modal(this.$refs.clinicalDataModal, { backdrop: 'static', keyboard: false });

		this.imageViewerModal = new Modal(this.$refs.imageViewerModal)

		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};
		this.loadLazyData();

		setTimeout(()=>{
			soc.on('eegReport', d=>{
				this.loadLazyData();
				notiAudio.play();
			})
		}, 0)
	},
	unmounted(){
		soc.off('eegReport');
	},
	methods: {
		printReport(row){
			let data =  btoa(row.center_id+','+row.report_id).replaceAll(/=/ig, "");

			this.$router.push({
				path: "/print-eeg-report",
				query: {
					data
				}
			})
		},
		update() {
			this.loadLazyData();
		},
		showNotesBox(data) {
			this.notes = data.notes;
			this.editRow = data;
			this.notesModal.show();
		},
		showReviewNotesBox(data) {
			this.editRow = data;
			this.reviewNotesModal.show();
		},
		showClinicalData(data) {
			this.editRow = data;
			this.clinicalDataModal.show();
		},
		showImageViewer(data) {
			if (!data.images) return;
			this.editRow = data;
			this.allImages = data.images.split(",");
			for (let i in this.allImages) {
				this.allImages[i] = {
					file: this.allImages[i],
					isPdf: this.allImages[i].indexOf(".pdf") != -1
				}
			}

			this.showImage = this.allImages[0];

			this.imageViewerModal.show();
		},
		saveNotes() {
			this.post("neurologist/save-notes", {
				notes: this.notes,
				report_id: this.editRow.report_id
			}).then(() => {
				this.notesModal.hide();
				this.loadLazyData();
			})
		},
		async resetHasDirectReport() {
			await this.get('neurologist/reset-has-direct-report');
			this.neurologist = await this.get("neurologist/load-neurologist");
		},
		async acceptReport(data) {
			let accept = await this.post("neurologist/toggle-accept-report", { report_id: data.report_id });
			if (!accept.success) this.$toast.add({ severity: 'warn', summary: 'Accepted By Another Doctor', detail: 'This report has already been accepted by another doctor, please try another report.', life: 3000 });
			this.neurologist = await this.get("neurologist/load-neurologist");
			this.loadLazyData();
		},
		async loadLazyData() {
			this.loading = true;
			this.refreshTime = new Date();
			let params = JSON.parse(JSON.stringify(this.lazyParams));
			if (!params.rows) return;

			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}
			if (!params.filters) params.filters = {};


			let page = this.$route.params.page;
			this.page = page;

			if (page == 'completed') {
				if (!params.sortField) {
					params.sortField = "id";
					params.sortOrder = -1;
				}
				params.filters['status'] = { value: "pending", matchMode: "!=" };
			}
			if (page == 'review') {
				if (!params.sortField) {
					params.sortField = "id";
					params.sortOrder = 1;
				}
				params.filters['status'] = { value: "review", matchMode: "=" };
			}
			if (page == 'pending') {
				if (!params.sortField) {
					params.sortField = "urgent";
					params.sortOrder = 1;
				}
				params.filters['status'] = { value: "pending", matchMode: "=" };

			}
			if (page == 'specific') {
				if (!params.sortField) {
					params.sortField = "id";
					params.sortOrder = -1;
				}
				params.filters['assign_type'] = { value: "specific", matchMode: "=" };
			}
			this.neurologist = await this.get("neurologist/load-neurologist");


			params.joins = [
				{
					"fTable": "eeg_reports",
					"fKey": "report_id",
					"fField": ["center_id", "patient_name", "patient_age", "patient_gender", "ref_doctor_name", 'bp_systole', 'bp_diastole', 'spo2', 'pulse_rate', 'height', 'weight', 'symptoms', "test", "test_date", "activation_procedure", "sedation_given", "medicine", "history", "reg_no", "mobile_no", "assign_doc_id", "assign_doc_ids", "assign_type", "images", "report", "status", "urgent", "review_notes", "notes", "accepted_at", "created_at", "reported_at"]
				},
				{
					"fTable": ["eeg_reports", "neurologists"],
					"fKey": "assign_doc_id",
					"fField": [["name", "assign_doc_name"]]
				},
				{
					"fTable": ["eeg_reports", "centers"],
					"fKey": "center_id",
					"fField": [["code", "center_code"]]
				},
				// {
				// "fTable": ["eeg_reports", "tests"],
				// "fKey": "test_id",
				// "fField": [ ["name", "test_name"] ]
				// },
			]


			this.post("neurologist/load-pivot_eeg_report_doctor", params).then(res => {
				this.loading = false;
				if (!res.success) return;

				this.totalRecords = res.totalRecords;
				this.tableRecords = res.rows;

				if (this.timerWorking) return;
				this.timerWorking = true;

				let w = () => {
					if (document.body.contains(this.$refs.container)) {
						for (let row of this.tableRecords) {
							row.accepted = row.assign_doc_id != 0;
							if (row.accepted_at) {
								let t1 = new Date(row.accepted_at);
								t1.setTime(t1.getTime() + 30 * 60 * 1000);
								let t2 = new Date();
								let td = t1.getTime() - t2.getTime();
								td = td / 1000;
								if (td < 0) td = 0;
								let m = String(Math.floor(td / 60));
								if (m.length == 1) m = '0' + m;
								let s = String(Math.floor(td % 60));
								if (s.length == 1) s = '0' + s;

								row.timer = '00:' + m + ':' + s;
							}
						}
						setTimeout(w, 1000);
					}
				}
				w();


			});
		},
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			this.loading = true;
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		},
		async deletePivotReport(row){
			await this.post('neurologist/delete-pivot-report', {
				id: row.id
			});
			this.imageViewerModal.hide();
			this.loadLazyData();
		}
	}
}
</script>
<style scoped>
</style>




